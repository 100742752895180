
import {computed, defineComponent, ref} from 'vue'
import store from "@/store";
import Avatar from "@/components/base/common/Avatar.vue";

export default defineComponent({
  name: "UserContacts",
  components: { Avatar},
  emits: ['selectUser'],
  setup() {
    const contacts = computed(() => store.state.ChatModule.contacts);
    const showOnline = ref(false);
    return {
      showOnline,
      contacts
    }
  },
  methods: {

  }
})
