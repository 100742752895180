
import {computed, defineComponent} from 'vue'
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import UserContacts from "@/views/chat/UserContacts.vue";
import store from "@/store";

export default defineComponent({
  name: "Chat",
  components: { UserContacts},
  setup() {

    setCurrentPageBreadcrumbsWithParams('Chat', [
      {link: false, router: '', text: 'Chat'},
    ]);
    const clientConnected = computed(()=>store.state.WebSocketModule.clientConnected);
    return {
      clientConnected,
    }
  }
})
